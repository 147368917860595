<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    this.getTitle();
  },

  methods: {
    getTitle() {
      document.title = this.$t("global.title");
    },
  },
};
</script>
<style lang="scss">
html,
body,
#app {
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 1280px;
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif, "宋体";
}

.hy-main {
  background-color: #fff;
}

.cur_router {
  background-color: #f4f5fa;
}

.el-picker-panel {
  .prev-month,
  .next-month {
    opacity: 0;
  }
}

.el-tooltip__popper {
  max-width: 600px;
}
</style>


